import { LeftArrowTipIcon, RightArrowTipIcon } from '@components/icons'
import clsx from 'clsx'
import { FC, useState } from 'react'

const circularButtonStyle = 'w-8 h-8 rounded-full border border-color-5 flex justify-center items-center'

export const Pagination: FC<{
	handleNext: () => void
	handlePrev: () => void
	index: number
	pages: number
	maxDots?: number
	circularButtons?: boolean
}> = ({ handleNext, handlePrev, index, pages, maxDots = 15, circularButtons }) => {
	const buttonStyle = clsx('mx-2 shrink-0', circularButtons && circularButtonStyle)
	return (
		<div className="flex flex-row max-w-max min-w-max justify-center items-center gap-2">
			{/* Left Caret */}
			<button onClick={handlePrev} className={buttonStyle}>
				<LeftArrowTipIcon className="stroke-color-6 w-3 h-3" />
			</button>
			{/* Use a fraction instead of dots if pages exceed maxDots */}
			{pages > maxDots ? (
				<span className="text-color-7">
					{index + 1} / {pages}
				</span>
			) : (
				Array(pages)
					.fill(0)
					.map((v, i) => (
						<div key={i} className={clsx('shrink-0 rounded-full w-1 h-1 bg-color-3', i === index && 'bg-color-6')} />
					))
			)}
			{/* Right Caret */}
			<button onClick={handleNext} className={buttonStyle}>
				<RightArrowTipIcon className="stroke-color-6 w-3 h-3" />
			</button>
		</div>
	)
}

export const usePaginationHandlers = (
	pages: number
): { index: number; handleNext: () => void; handlePrev: () => void } => {
	const [index, setIndex] = useState(0)

	const handleNext = (): void => {
		setIndex((i) => (i + 1) % pages)
	}

	const handlePrev = (): void => {
		setIndex((i) => {
			if (i === 0) return ((i - 1) % pages) + pages
			return i - (1 % pages)
		})
	}

	return { index, handleNext, handlePrev }
}
