import useCopyClipboard from '@hooks/useCopyClipboard'
import clsx from 'clsx'
import { FC, PropsWithChildren, useState } from 'react'

type TagProps = PropsWithChildren<{
	className?: string

	/** Text content of the tag */
	label?: string

	/** Setting to true allows the tag to be clickable and for contents to be copied on clipboard */
	copyable?: boolean

	/** Specify content to be desired content to be copied, if not the label */
	copyableContent?: string

	/** Add an icon to the left of the tag text */
	iconLeft?: JSX.Element

	/** Add an icon to the right of the tag text */
	iconRight?: JSX.Element

	/** Specifies the style of the tag */
	variant?: 'primary' | 'secondary' | 'secondaryInverted'
}>

const Tag: FC<TagProps> = ({
	className,
	label,
	iconLeft,
	iconRight,
	variant = 'primary',
	copyable = false,
	copyableContent,
	children,
}) => {
	const [isCopied, copy] = useCopyClipboard(false, 1500)
	const [hoverText, setHoverText] = useState('')

	const handleCopy = (): void => {
		if (!copyable) return

		copy(copyableContent ?? label)
	}

	const classes = clsx(className, 'tag-base', {
		'tag-primary': variant === 'primary',
		'tag-secondary': variant === 'secondary',
		'tag-secondary-inverted': variant === 'secondaryInverted',
	})

	const copyableText = isCopied ? 'COPIED' : hoverText

	const contents = (
		<>
			{iconLeft && <div className="mr-1.5 flex items-center">{iconLeft}</div>}
			{children}
			{label && (
				<div className="h-full flex items-center leading-none align-middle my-auto">
					{copyable && copyableText ? copyableText : label}
				</div>
			)}
			{iconRight && <div className="ml-1.5 flex items-center">{iconRight}</div>}
		</>
	)

	return (
		<>
			{copyable ? (
				<button
					onMouseOver={() => setHoverText('COPY')}
					onMouseLeave={() => setHoverText('')}
					onClick={handleCopy}
					className={clsx(classes, 'hover:text-color-8 smooth-hover')}
				>
					{contents}
				</button>
			) : (
				<span className={classes}>{contents}</span>
			)}
		</>
	)
}

export { Tag }
